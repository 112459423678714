import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-anfahrt",
  templateUrl: "./anfahrt.component.html",
  styleUrls: ["./anfahrt.component.css"]
})
export class AnfahrtComponent implements OnInit {
  constructor() {}

  public title = "Hier findest du uns";

  ngOnInit() {}
}
