import { Component, OnInit, HostListener, ViewChild } from "@angular/core";
import { Router } from "@angular/router";

import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
declare var $: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  public config: PerfectScrollbarConfigInterface = {};

  @ViewChild("LeftSidebar", { static: false })
  public leftSidebar: any;

  constructor(public router: Router) { }

  public innerWidth: any;
  public defaultSidebar: any;
  public showMobileMenu = false;
  public expandLogo = false;
  public sidebartype = "full";
  public showInfoBanner = true;

  ngOnInit() {
    if (this.router.url === "/") {
      this.router.navigate(["/home"]);
    }
    this.defaultSidebar = this.sidebartype;
    this.handleSidebar();
  }

  Logo() {
    this.expandLogo = !this.expandLogo;
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.handleSidebar();
  }

  handleSidebar() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1170) {
      this.sidebartype = "mini-sidebar";
    } else {
      this.sidebartype = this.defaultSidebar;
    }
  }

  toggleSidebarType() {
    switch (this.sidebartype) {
      case "full":
        this.sidebartype = "mini-sidebar";
        break;

      case "mini-sidebar":
        this.sidebartype = "full";
        break;

      default:
    }
  }

  toggleSidebarOff() {
    if (this.showMobileMenu === true) {
      this.showMobileMenu = false;
    }
  }
}
