import { RouteInfo } from "./sidebar.metadata";

export const ROUTES: RouteInfo[] = [
  {
    path: "/home",
    title: "Home",
    icon: "mdi mdi-home",
    class: "",
    extralink: false,
    submenu: [],
  } /*
  {
    path: "/ueberuns",
    title: "Über uns",
    icon: "mdi mdi-human",
    class: "",
    extralink: false,
    submenu: []
  }, */ /*
  {
    path: "/aktuelles",
    title: "Aktuelles",
    icon: "mdi mdi-clock",
    class: "",
    extralink: false,
    submenu: []
  }, */,
  {
    path: "/anfahrt",
    title: "Anfahrt",
    icon: "mdi mdi-car",
    class: "",
    extralink: false,
    submenu: [],
  },
  {
    path: "/impressum",
    title: "Impressum",
    icon: "mdi mdi-contact-mail",
    class: "",
    extralink: false,
    submenu: [],
  },
  {
    path: "/datenschutz",
    title: "Datenschutz",
    icon: "mdi mdi-lock",
    class: "",
    extralink: false,
    submenu: [],
  },
  /*
  ,
  {
    path: "",
    title: "",
    icon: "",
    class: "",
    extralink: false,
    submenu: []
  },
  {
    path: "",
    title: "UI Components",
    icon: "mdi mdi-dots-horizontal",
    class: "nav-small-cap",
    extralink: true,
    submenu: []
  },
  {
    path: "/test",
    title: "Accordion",
    icon: "mdi mdi-equal",
    class: "",
    extralink: false,
    submenu: []
  },
  {
    path: "/alert",
    title: "Alert",
    icon: "mdi mdi-message-bulleted",
    class: "",
    extralink: false,
    submenu: []
  },
  {
    path: "/carousel",
    title: "Carousel",
    icon: "mdi mdi-view-carousel",
    class: "",
    extralink: false,
    submenu: []
  },
  {
    path: "/dropdown",
    title: "Dropdown",
    icon: "mdi mdi-arrange-bring-to-front",
    class: "",
    extralink: false,
    submenu: []
  },
  {
    path: "/modal",
    title: "Modal",
    icon: "mdi mdi-tablet",
    class: "",
    extralink: false,
    submenu: []
  },
  {
    path: "/pagination",
    title: "Pagination",
    icon: "mdi mdi-backburger",
    class: "",
    extralink: false,
    submenu: []
  },
  {
    path: "/poptool",
    title: "Popover & Tooltip",
    icon: "mdi mdi-image-filter-vintage",
    class: "",
    extralink: false,
    submenu: []
  },
  {
    path: "/progressbar",
    title: "Progressbar",
    icon: "mdi mdi-poll",
    class: "",
    extralink: false,
    submenu: []
  },
  {
    path: "/rating",
    title: "Ratings",
    icon: "mdi mdi-bandcamp",
    class: "",
    extralink: false,
    submenu: []
  },
  {
    path: "/tabs",
    title: "Tabs",
    icon: "mdi mdi-sort-variant",
    class: "",
    extralink: false,
    submenu: []
  },
  {
    path: "/timepicker",
    title: "Timepicker",
    icon: "mdi mdi-calendar-clock",
    class: "",
    extralink: false,
    submenu: []
  },
  {
    path: "/card",
    title: "Card",
    icon: "mdi mdi-arrange-bring-forward",
    class: "",
    extralink: false,
    submenu: []
  },
  {
    path: "/toast",
    title: "Toast",
    icon: "mdi mdi-alert",
    class: "",
    extralink: false,
    submenu: []
  }
  */
];
