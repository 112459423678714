import { HomeComponent } from "./component/home/home.component";
// import * as $ from 'jquery';
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  CommonModule,
  LocationStrategy,
  PathLocationStrategy,
  HashLocationStrategy
} from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { Routes, RouterModule } from "@angular/router";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { NavigationComponent } from "./shared/header-navigation/navigation.component";
import { SidebarComponent } from "./shared/sidebar/sidebar.component";
import { BreadcrumbComponent } from "./shared/breadcrumb/breadcrumb.component";

import { Approutes } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SpinnerComponent } from "./shared/spinner.component";

import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { UeberUnsComponent } from "./component/ueber-uns/ueber-uns.component";
import { ImpressumComponent } from "./component/impressum/impressum.component";
import { AnfahrtComponent } from "./component/anfahrt/anfahrt.component";
import { AktuellesComponent } from "./component/aktuelles/aktuelles.component";
import { DatenschutzComponent } from './component/datenschutz/datenschutz.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 1,
  wheelPropagation: true,
  minScrollbarLength: 20
};

@NgModule({
  declarations: [
    UeberUnsComponent,
    AppComponent,
    SpinnerComponent,
    NavigationComponent,
    SidebarComponent,
    BreadcrumbComponent,
    HomeComponent,
    HomeComponent,
    UeberUnsComponent,
    ImpressumComponent,
    AnfahrtComponent,
    AktuellesComponent,
    DatenschutzComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    PerfectScrollbarModule,
    NgbModule,
    CommonModule,
    FormsModule,
    NgbModule,
    RouterModule.forRoot(Approutes, { useHash: false })
  ],
  providers: [
    /*
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }, */
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
