import { Title } from "@angular/platform-browser";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-impressum",
  templateUrl: "./impressum.component.html",
  styleUrls: ["./impressum.component.css"]
})
export class ImpressumComponent implements OnInit {
  constructor() {}

  public title: String = "Impressum";

  ngOnInit() {}
}
