import { DatenschutzComponent } from "./component/datenschutz/datenschutz.component";
import { AktuellesComponent } from "./component/aktuelles/aktuelles.component";
import { AnfahrtComponent } from "./component/anfahrt/anfahrt.component";
import { ImpressumComponent } from "./component/impressum/impressum.component";
import { UeberUnsComponent } from "./component/ueber-uns/ueber-uns.component";
import { HomeComponent } from "./component/home/home.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

export const Approutes: Routes = [
  /* {
    path: "",
    component: FullComponent,
    children: [{ path: "", redirectTo: "/home", pathMatch: "full" }]
  }, */
  {
    path: "ueberuns",
    component: UeberUnsComponent,
  },
  {
    path: "home",
    component: HomeComponent,
    data: {
      title: "Home",
    },
  },
  {
    path: "impressum",
    component: ImpressumComponent,
  },
  {
    path: "anfahrt",
    component: AnfahrtComponent,
  },
  {
    path: "aktuelles",
    component: AktuellesComponent,
  },
  {
    path: "datenschutz",
    component: DatenschutzComponent,
  },
];
